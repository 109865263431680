var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_authorCard"},[_c('TitleField',{attrs:{"field_name":'name',"label":_vm.$t('name'),"content":_vm.author.name,"path":_vm.author.$path,"required":true,"minlength":3,"maxlength":40,"tag":'h2',"can_edit":_vm.is_self}}),_c('small',[_vm._v(" "+_vm._s(_vm.author.$path)+" ")]),_c('br'),_c('SelectField',{attrs:{"field_name":'role',"content":_vm.author.role,"path":_vm.author.$path,"can_edit":_vm.is_self,"options":[
      {
        key: 'contributor',
        text: _vm.$t('contributor'),
        instruction: _vm.$t('contributor_instructions'),
      },
      {
        key: 'admin',
        text: _vm.$t('admin'),
        instruction: _vm.$t('admin_instructions'),
      } ]}}),_c('br'),(_vm.is_self || _vm.is_admin)?_c('RemoveMenu',{attrs:{"remove_text":_vm.$t('remove')},on:{"remove":_vm.removeAuthor}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }