<template>
  <span class="_pickNativePath">
    <DLabel :str="label" :instructions="can_edit ? instructions : ''" />

    <div class="_sameLine">
      <input type="text" required readonly v-model="new_content" />
      <EditBtn v-if="can_edit && !edit_mode" @click="enableEditMode" />
    </div>

    <br />

    <template v-if="can_edit">
      <template v-if="edit_mode">
        <button
          type="button"
          class="u-button u-button_bleuvert"
          @click="changeStorage"
        >
          Sélectionner un chemin sur le disque
        </button>

        <br />
        <br />

        <div class="_footer">
          <SaveCancelButtons
            class="_scb"
            :is_saving="is_saving"
            :allow_save="allow_save"
            @save="updateText"
            @cancel="cancel"
          />
        </div>
      </template>
    </template>
  </span>
</template>
<script>
export default {
  props: {
    field_name: String,
    label: {
      type: String,
      default: "",
    },
    instructions: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    path: String,
    required: {
      type: Boolean,
      default: false,
    },
    can_edit: {
      type: Boolean,
    },
  },
  components: {},
  data() {
    return {
      edit_mode: false,
      is_saving: false,
      new_content: this.content,

      current_character_count: undefined,
      allow_save: false,
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {
    content() {
      this.new_content = this.content;
    },
  },
  computed: {},
  methods: {
    enableEditMode() {
      this.edit_mode = true;
    },
    changeStorage() {
      window.electronAPI.send("toMain", {
        type: "get_path",
      });
      window.electronAPI.receive("fromMain", ({ type, path_to_content }) => {
        if (type === "new_path") {
          this.new_content = path_to_content;
          this.edit_mode = true;
          this.allow_save = this.new_content !== this.content;
        }
      });
    },
    cancel() {
      this.edit_mode = false;
      this.is_saving = false;
      this.new_content = this.content;

      this.$nextTick(() => {
        // this.content = "";
        // this.$nextTick(() => {
        // this.content = this.new_content;
        // });
      });

      // todo interrupt updateMeta
    },
    async updateText() {
      this.is_saving = true;
      await new Promise((r) => setTimeout(r, 50));

      try {
        const new_meta = {
          [this.field_name]: this.new_content,
        };

        await this.$api.updateMeta({
          path: this.path,
          new_meta,
        });

        this.edit_mode = false;
        this.is_saving = false;
      } catch (e) {
        this.is_saving = false;

        this.$alertify
          .closeLogOnClick(true)
          .delay(4000)
          .error(this.$t("notifications.couldntbesaved"));
        this.$alertify.closeLogOnClick(true).error(e.response.data);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
._pickNativePath {
  width: 100%;

  ._content {
    white-space: break-spaces;
    margin-right: calc(var(--spacing) / 2);
  }
}

._footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  padding: calc(var(--spacing) / 4) 0;
  gap: calc(var(--spacing) / 4);
}

._container {
  margin: 0;
}

._cont {
  display: inline-grid;
  align-items: stretch;

  &::after,
  textarea {
    grid-area: 2/1;

    width: auto;
    min-width: 1em;
    font: inherit;
    margin: 0;
    resize: none;
    padding: 0.25em;
  }

  &::after {
    content: attr(data-value) " ";
    visibility: hidden;
    white-space: break-spaces;
  }
}

._scb {
}

._sameLine {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: calc(var(--spacing) / 4);
}
</style>
