var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal2',{attrs:{"title":_vm.$t('contributors')},on:{"close":function($event){return _vm.$emit('close')}}},[_c('div',[(!_vm.connected_as)?[_c('RadioSwitch',{attrs:{"content":_vm.current_mode,"options":[
          {
            label: _vm.$t('login'),
            value: 'login',
          },
          {
            label: _vm.$t('create_account'),
            value: 'create',
          } ]},on:{"update:content":function($event){_vm.current_mode=$event}}}),_c('br'),(_vm.current_mode === 'login')?_c('LoginAs',{attrs:{"authors":_vm.authors},on:{"close":function($event){return _vm.$emit('close')}}}):(_vm.current_mode === 'create')?_c('CreateAuthor',{on:{"close":function($event){return _vm.$emit('close')}}}):_vm._e(),_c('br')]:(_vm.connected_as)?_c('fieldset',[_c('legend',{staticClass:"u-label"},[_vm._v(_vm._s(_vm.$t("your_account")))]),_c('AuthorCard',{key:_vm.connected_as.$path,attrs:{"author":_vm.connected_as}})],1):_vm._e(),_c('br'),(_vm.connected_as)?[_c('button',{staticClass:"u-button u-button_red",attrs:{"type":"button"},on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.$t("logout"))+" ")]),_c('br'),_c('br')]:_vm._e(),_c('DLabel',{attrs:{"str":_vm.$t('list_of_contributors')}}),(_vm.authors.length === 0)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("no_accounts_yet"))+" ")]):_c('button',{staticClass:"u-button",attrs:{"type":"button"},on:{"click":function($event){_vm.show_authors_list = !_vm.show_authors_list}}},[_vm._v(" "+_vm._s(_vm.$t("show_list"))+" ("+_vm._s(_vm.authors_except_self.length)+") ")]),(_vm.show_authors_list)?_c('div',{staticClass:"_listOfAuthors"},_vm._l((_vm.authors_except_self),function(author){return _c('AuthorCard',{key:author.$path,attrs:{"author":author}})}),1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }