import { render, staticRenderFns } from "./BaseModal2.vue?vue&type=template&id=523e8c49&scoped=true&"
import script from "./BaseModal2.vue?vue&type=script&lang=js&"
export * from "./BaseModal2.vue?vue&type=script&lang=js&"
import style0 from "./BaseModal2.vue?vue&type=style&index=0&id=523e8c49&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "523e8c49",
  null
  
)

export default component.exports